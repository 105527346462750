import Alpine from 'alpinejs';

Alpine.store('filters', {
  // filters FORMAT:
  // {
  //   type: ['red', 'white'],
  // }
  loading: false,
  loadingStack: 0,
  open: {},
  search: null,
  filters: {},
  filterTypes: {},
  persistedFilterType: null,
  init() {
    // Array.from(document.getElementById('wine-filters').getElementsByClassName('filter-input')).forEach((input) => {
    //   this.addFilter(input.name, input.value, input.dataset.type);
    // });
  },
  check(name, el, load = true) {
    if (el.checked) {
      this.addFilter(name, el.value, el.dataset.type, load);
    } else {
      this.removeFilter(name, el.value, load);
    }
  },
  addFilter(name, value, type, load = false) {
    if (typeof this.filters[name] === 'undefined') {
      this.filters[name] = [];
      this.filterTypes[name] = type;
    }

    if (this.filters[name].indexOf(value) === -1) {
      this.filters[name].push(value);
    }
    if (load) this.load({ type: name });
  },
  removeFilter(name, value, load = false) {
    if (typeof this.filters[name] === 'undefined') {
      return;
    }

    this.filters[name] = this.filters[name].filter((item) => item !== value);
    if (load) this.load({ type: name });
  },
  hasFilter(name, value) {
    return (this.filters[name] && this.filters[name].indexOf(value) !== -1) || false;
  },
  isChecked(el) {
    return this.hasFilter(el.name, el.value);
  },
  filtersCount(filterKey = null, total = 0) {
    if (filterKey) {
      return (this.filters[filterKey] && this.filters[filterKey].length) || total;
    }

    return Object.keys(this.filters).reduce((sum, key) => sum + this.filters[key].length, total);
  },
  clearAllFilters() {
    Object.keys(this.filters).forEach((key) => {
      this.filters[key] = [];
    });
    this.load();
  },
  clearFilter(filterKey, load = true) {
    if (this.filters[filterKey]) {
      this.filters[filterKey] = [];
    }
    if (load) {
      this.load();
    }
  },
  updateSearch() {
    const url = new URL(window.location.href);
    const { searchParams } = url;
    searchParams.set('search', this.search);
    url.search = searchParams.toString();
    window.history.replaceState({}, '', url.href);
    this.load();
  },
  load({ type = null } = {}) {
    const params = new URLSearchParams(window.location.search);
    params.delete('page');

    const { filters } = this;

    Object.keys(filters).forEach((key) => {
      let normalizedKey;
      if (key === 'format') {
        // format is a reserved query param in Rails
        normalizedKey = 'bottle_format';
      } else {
        normalizedKey = key;
      }

      params.set(normalizedKey, filters[key].map((value) => {
        if (value === null || value === 'null') {
          return '=';
        }
        if (this.filterTypes[key] === 'string' || this.filterTypes[key] === 'numeric') {
          return `=${value}`;
        }

        return value;
      }).join(','));
    });

    const { protocol, pathname } = window.location;
    const url = `${protocol}${pathname}?${params.toString()}`;
    // Use different URL to avoid Chrome stalling requests with same URL
    const filtersUrl = `${protocol}${pathname.replace('portfolio', 'filters')}?${params.toString()}`;
    window.history.replaceState({}, '', url);

    const event = new Event('turbo:before-fetch-request');
    document.dispatchEvent(event);

    // send a first request to get the results
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'Turbo-Frame': 'portfolio_results',
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      });

    this.persistedFilterType = type;
    this.loading = true;
    this.loadingStack += 1;

    // send a second (slower) request to update the filters
    fetch(filtersUrl, {
      method: 'GET',
      headers: {
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'Turbo-Frame': `portfolio_filters_${type}`,
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.loadingStack -= 1;
        if (this.loadingStack <= 0) {
          this.persistedFilterType = null;
          this.loading = false;
        }
      });
  }
});
