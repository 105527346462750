import Alpine from 'alpinejs';

Alpine.store('order', {
  deliveryMethod: null,
  shippingDate: null,
  pickupTime: null,
  paymentMethod: null,
  deliveryInstructions: null,
  loading: false,
  link: null,

  assignAttributes({ deliveryMethod, shippingDate, pickupTime, paymentMethod, deliveryInstructions }) {
    if (deliveryMethod !== null) {
      this.deliveryMethod = deliveryMethod;
    }
    if (shippingDate !== null) {
      this.shippingDate = shippingDate;
    }
    if (pickupTime !== null) {
      this.pickupTime = pickupTime;
    }
    if (paymentMethod !== null) {
      this.paymentMethod = paymentMethod;
    }
    if (deliveryInstructions !== null) {
      this.deliveryInstructions = deliveryInstructions;
    }
  },

  update({ deliveryMethod, shippingDate, pickupTime, paymentMethod, deliveryInstructions }) {
    this.loading = true;
    this.assignAttributes({ deliveryMethod, shippingDate, pickupTime, paymentMethod, deliveryInstructions });

    const token = document.getElementsByName('csrf-token')[0]?.content;

    const event = new Event('turbo:before-fetch-request');
    document.dispatchEvent(event);

    fetch('/checkout/orders', {
      method: 'PATCH',
      body: JSON.stringify({
        link: this.link,
        cart: {
          delivery_method: this.deliveryMethod,
          shipping_date: this.shippingDate,
          pickup_time: this.pickupTime,
          payment_method: this.paymentMethod,
          delivery_instructions: this.deliveryInstructions,
        },
      }),
      headers: {
        'X-CSRF-Token': token,
        Accept: 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml',
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then((response) => response.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
        this.loading = false;
      });
  }
});
