import Alpine from 'alpinejs';

// Google Analytics
Alpine.store('ga', {
  sendEvent: ({ category, action, label, value }) => {
    if (typeof gtag === 'function') {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      });
    }
  },
  tagPurchase: ({ transactionId, paymentMethod, email, items, subtotal, shipping }) => {
    if (typeof gtag === 'function') {
      gtag('event', 'purchase', {
        transaction_id: transactionId,
        payment_method: paymentMethod,
        email,
        items,
        subtotal,
        shipping,
      });
    }
  },
});
