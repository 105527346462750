import Alpine from 'alpinejs';

document.addEventListener('alpine:init', () => {
  Alpine.data('email', () => ({
    show(e, options) {
      if (e.currentTarget.dataset.email == null) {
        return;
      }
      e.preventDefault();

      const { email } = e.currentTarget.dataset;
      e.currentTarget.href = `mailto:${atob(email)}`;
      e.currentTarget.removeAttribute('data-email');
      if (options.updateText) { e.currentTarget.innerText = atob(email); }
      if (options.autoClick) { e.currentTarget.click(); }
    },
  }));
});
