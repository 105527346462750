import Alpine from 'alpinejs';

Alpine.store('sort', {
  sort: '',
  init() {
    const params = new URLSearchParams(window.location.search);
    this.sort = params.get('sort');
  },
  change(el) {
    this.sort = el.value;
    this.load();
  },
  load() {
    const params = new URLSearchParams(window.location.search);
    params.set('sort', this.sort);

    const frame = document.querySelector('turbo-frame#portfolio_results');
    const { protocol, pathname } = window.location;
    frame.src = `${protocol}${pathname}?${params.toString()}`;
    window.history.replaceState({}, '', frame.src);
  }
});
