import Alpine from 'alpinejs';

Alpine.store('photoViewer', {
  show: false,
  url: null,
  display(photoUrl) {
    this.url = photoUrl;
    this.show = true;
  },
  close() {
    this.url = null;
    this.show = false;
  }
});
