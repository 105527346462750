import Alpine from 'alpinejs';

Alpine.store('product', {
  items: {},
  selectedItems: {},
  highlightedItem: null,

  addItem(productIndex, itemId) {
    this.selectedItems[productIndex] ??= [];
    this.selectedItems[productIndex].push(itemId);
  },

  removeItem(productIndex, itemId) {
    this.selectedItems[productIndex] ??= [];
    this.selectedItems[productIndex] = this.selectedItems[productIndex].filter((id) => id !== itemId);
  },

  push(productIndex, count = 1) {
    this.selectedItems[productIndex] ??= [];
    const newArray = [...this.selectedItems[productIndex]];
    const availableItems = this.items[productIndex].sort();

    for (i = 0; i < count; i += 1) {
      for (j = 0; j < availableItems.length; j += 1) {
        if (!newArray.includes(availableItems[j])) {
          newArray.push(availableItems[j]);
          break;
        }
      }
    }

    this.selectedItems[productIndex] = newArray;
  },

  pop(productIndex, count = 1) {
    this.selectedItems[productIndex] ??= [];
    const newArray = [...this.selectedItems[productIndex]].sort();
    for (i = 0; i < count; i += 1) {
      newArray.pop();
    }
    this.selectedItems[productIndex] = newArray;
  },

  setQuantity(productIndex, quantity) {
    this.selectedItems[productIndex] ??= [];
    if (quantity > this.selectedItems[productIndex].length) {
      this.push(productIndex, quantity - this.selectedItems[productIndex].length);
    } else if (quantity < this.selectedItems[productIndex].length) {
      this.pop(productIndex, this.selectedItems[productIndex].length - quantity);
    }
  },

  isSelected(productIndex, itemId) {
    return this.selectedItems[productIndex]?.includes(itemId);
  },
});
