import Alpine from 'alpinejs';

Alpine.store('search', {
  loading: false,
  products: [],
  search(value) {
    sessionStorage.setItem('search', value);

    if (!value) {
      this.products = [];
      return;
    }

    this.loading = true;

    fetch(`/search?query=${value}&items=100`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        this.products = json.products;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  },
});
