import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';

import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import moment from '@victoryoalli/alpinejs-moment';
import timeout from '@victoryoalli/alpinejs-timeout';
import './store/filters';
import './store/order';
import './store/product';
import './store/search';
import './store/sort';
import './store/ga';
import './store/photo_viewer';
import './store/recommendations_modal';
import './data/email';
import Numeral from 'numeral';

Alpine.plugin(focus);

Turbo.session.drive = false;

document.addEventListener('turbo:before-fetch-request', (e) => {
  Turbo.navigator.delegate.adapter.showProgressBar();

  // https://github.com/hotwired/turbo/issues/491#issuecomment-986866952
  if (e.target instanceof HTMLFormElement) {
    const { fetchOptions: { method, headers } } = e.detail;

    if (method.match(/get/i) && e.target.dataset.turboStreaming === 'true') {
      headers.Accept = ['text/vnd.turbo-stream.html', headers.Accept].join(', ');
    }
  }
});

['turbo:frame-render', 'turbo:before-stream-render'].forEach((e) => {
  document.addEventListener(e, () => {
    Turbo.navigator.delegate.adapter.progressBar.hide();
  });
});

document.addEventListener('turbo:load', () => {
  document.querySelectorAll('img[loading=lazy]:not(.loaded)').forEach((img) => {
    img.addEventListener('load', () => {
      img.classList.add('loaded');
    });
  });
});

window.Numeral = Numeral;

Alpine.plugin(moment);
Alpine.plugin(timeout);
window.Alpine = Alpine;
window.Alpine.start();

// Load Rails UJS
Rails.start();
